export default function invariant(
  condition: any,
  message: string = "Uh oh, something went wrong. Please try again."
): asserts condition {
  if (condition) {
    return
  }

  throw new Error(message)
}
